.App {
  text-align: center;
}

body{
  position: relative;
  overflow-x: hidden;
  font-family: 'Josefin Sans', sans-serif;
}

body { font-size: 15px;}
h1 { font-size: 2.2rem;}
h2 { font-size: 1.8rem; }
h3 { font-size: 1.4rem; }
h4 { font-size: 1.1rem; }
h5 { font-size: 1rem; }

@media screen and (min-width:361px){
  body { font-size: 16px;}
  h1 { font-size: 2.4rem;}
  h2 { font-size: 2rem; }
  h3 { font-size: 1.6rem; }
  h4 { font-size: 1.2rem; }
  h5 { font-size: 1rem; }
}

@media screen and (min-width:768px){
  body { font-size: 16px;}
  h1 { font-size: 2.5rem;}
  h2 { font-size: 2.1rem; }
  h3 { font-size: 1.7rem; }
  h4 { font-size: 1.3rem; }
  h5 { font-size: 1rem; }
}

@media screen and (min-width:992px){
  html { font-size: 17px;}
  h1 { font-size: 2.7rem;}
  h2 { font-size: 2.2rem; }
  h3 { font-size: 1.8rem; }
  h4 { font-size: 1.4rem; }
  h5 { font-size: 1rem; }
}

@media screen and (min-width:1200px){
  html { font-size: 18px;}
  h1 { font-size: 2.8rem;}
  h2 { font-size: 2.3rem; }
  h3 { font-size: 1.8rem; }
  h4 { font-size: 1.4rem; }
  h5 { font-size: 1rem; }
}

@media screen and (min-width:1600px){
  html { font-size: 20px;}
  h1 { font-size: 3rem;}
  h2 { font-size: 2.5rem; }
  h3 { font-size: 2rem; }
  h4 { font-size: 1.5rem; }
  h5 { font-size: 1rem; }
}